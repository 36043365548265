import React, { useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Card, Stack, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import CustomChip from '../Approvals/Component/CreateApprovalForm/CustomChip';
import { FormContainer } from '../RiverusUI/Components/FormContainer';
import RISelectComponent from '../RiverusUI/Components/SelectComponent';
import { getSignatureType, updateSignatureType } from '../Services/setting';

const signature_format = [
  { id: 'imageSignaturePanelButton', name: 'Upload' },
  { id: 'inkSignaturePanelButton', name: 'Draw' },
  { id: 'textSignaturePanelButton', name: 'Text' },
];

const Settings = () => {
  const methods = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { control, setValue } = methods;

  const { data: signatureType } = useQuery({
    queryKey: ['signature-method'],
    queryFn: getSignatureType,
    select: (response: any) => response.results?.[0],
  });

  const { mutate, isPending } = useMutation({
    mutationFn: updateSignatureType,
    onSuccess: () => {
      enqueueSnackbar('Signature type updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    if (signatureType) {
      setValue('signature_format', signatureType.signature_format);
    }
  }, [signatureType]);

  const onSubmit = (data: any) => {
    mutate({
      id: signatureType.id,
      body: data,
    });
  };

  return (
    <FormContainer formContext={methods} onSuccess={onSubmit}>
      <Card sx={{ padding: '10px' }}>
        <Stack gap={2}>
          <Typography>Set the signature methods</Typography>
          <Stack direction="row">
            <Box flex={1}>
              <RISelectComponent
                name="signature_format"
                label="Signature Method"
                options={signature_format}
                control={control}
                isMultiselect
                renderCustomComponent={(value: any, props) => (
                  <CustomChip {...props} label={value?.name} />
                )}
                rules={{
                  validate: (value) => {
                    if (value.length === 0) {
                      return 'Please select at least one signature method';
                    }
                    return true;
                  },
                }}
              />
            </Box>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isPending}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Card>
    </FormContainer>
  );
};

export default Settings;
