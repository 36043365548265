import React from 'react';

import { Stack } from '@mui/material';
import { WebViewerInstance } from '@pdftron/webviewer';

import AddCustomIssue from './AddCustomIssue';
import CustomDeviationCard from './CustomDeviationCard';

interface Props {
  instance: WebViewerInstance | null;
  draftId: string;
  userIsOwner: boolean;
  handleOpenClauseLibraryDrawer: VoidFunction;
  customDeviationList: any[];
  combinedApproversIds: any[];
  deviationType: string;
  isDraftDropped?: boolean;
  draftGroups: any[];
}

const CustomDeviation: React.FC<Props> = ({
  instance,
  draftId,
  userIsOwner,
  handleOpenClauseLibraryDrawer,
  customDeviationList,
  combinedApproversIds,
  deviationType,
  isDraftDropped,
  draftGroups,
}) => {
  return (
    <Stack spacing={3}>
      <AddCustomIssue
        draftId={draftId}
        instance={instance}
        combinedApproversIds={combinedApproversIds}
        isDraftDropped={isDraftDropped}
        draftGroups={draftGroups}
      />
      {customDeviationList?.map((deviation: any, index: number) => (
        <CustomDeviationCard
          key={index}
          cardData={deviation}
          instance={instance}
          userIsOwner={userIsOwner}
          handleOpenClauseLibraryDrawer={handleOpenClauseLibraryDrawer}
          deviationType={deviationType}
          draftId={draftId}
          isDraftDropped={isDraftDropped}
        />
      ))}
    </Stack>
  );
};

export default CustomDeviation;
