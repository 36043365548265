import React, { createContext, useContext, useMemo } from 'react';

import { getSessionStorage } from '../../Authentication/Actions/authentication';

interface IContextProps {
  user_id: string;
  user_data: any;
  userIsAdmin: boolean;
  external_signatory_id: string;
  external_email_id: string;
  savedSignRefreshToken: string;
  userIsCreator: boolean;
  accessToken: string;
  external_auth_token: string;
}

// Create a context for user data
const UserDataContext = createContext<IContextProps | null>(null);

export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error('useUserData must be used within a UserDataProvider');
  }

  const {
    user_data,
    userIsAdmin,
    user_id,
    external_signatory_id,
    external_email_id,
    savedSignRefreshToken,
    userIsCreator,
    accessToken,
    external_auth_token,
  } = context;

  return {
    user_data,
    userIsAdmin,
    user_id,
    external_signatory_id,
    external_email_id,
    savedSignRefreshToken,
    userIsCreator,
    accessToken,
    external_auth_token,
  };
};

interface IProps {
  children: React.ReactNode;
}

// Create the provider component
export const UserDataProvider: React.FC<IProps> = ({ children }) => {
  const savedSignRefreshToken = getSessionStorage('signRefreshToken');
  const accessToken = getSessionStorage('accessToken');
  const external_auth_token = getSessionStorage('external_auth_token');

  const user_data = getSessionStorage('user_profile');

  const external_signatory_id = getSessionStorage('external_signatory_id');

  const external_email_id = getSessionStorage('external_email');

  const userIsAdmin = useMemo(
    () => user_data?.roles?.includes('admin'),
    [user_data?.roles]
  );

  const userIsCreator = useMemo(
    () => user_data?.roles?.includes('Creators'),
    [user_data?.roles]
  );

  const value = useMemo(
    () => ({
      user_data,
      userIsAdmin,
      user_id: user_data?.id,
      external_signatory_id,
      external_email_id,
      savedSignRefreshToken,
      userIsCreator,
      accessToken,
      external_auth_token,
    }),
    [user_data]
  );

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
};
