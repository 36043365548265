export const setSessionStorage = (key, value) => {
  if (window !== 'undefined') {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const getSessionStorage = (key) => {
  if (window !== 'undefined') {
    if (window.sessionStorage.getItem(key) !== 'undefined') {
      return JSON.parse(window.sessionStorage.getItem(key));
    } else {
      return null;
    }
  }
};

export const setKeyCloakRealmOnLS = (realm) => {
  if (realm) {
    setSessionStorage('kcRealm', realm);
  }
};

export const getKeyCloakRealmFromLS = () => {
  const realmName = getSessionStorage('kcRealm');

  return realmName;
};

export const removeKeyCloakRealmOnLS = () => {
  removeSessionStorage('kcRealm');
};

export const setUserId = (key, value) => {
  if (window !== 'undefined') {
    window.sessionStorage.setItem(key, value);
  }
};

export const getUserId = (key) => {
  if (window !== 'undefined') {
    return JSON.parse(window.sessionStorage.getItem(key));
  }
};
