import React, { useEffect, useMemo, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, Tab, Tabs } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import {
  contractSupportDocsExt,
  createDraft,
  editDraftDataExternal,
  fetchExternalStamps,
  fetchPresignedUrlExternal,
  getDraftById,
  sendEmailExt,
  updateExtStampPaper,
} from './Services/Draft';
import {
  getSessionStorage,
  removeSessionStorage,
} from '../Authentication/Actions/authentication';
import ChecklistTab from '../Draft/Component/ChecklistTab/ChecklistTab';
import CompleteDraft from '../Draft/Component/CompleteDraft/CompleteDraft';
import PdfView from '../Draft/Component/PdfView';
import SignatureTab from '../Draft/Component/SignatureTab/SignatureTab';
import { draftStatus } from '../Draft/State/DraftState';
import { a11yProps } from '../RiverusUI/Components/CustomTabPanel';
import DraftHeader from '../RiverusUI/Header/DraftHeader';
import { riPrimary } from '../RiverusUI/Theme/colors';
import Footer from '../UniversalComponents/Footer/footer';

const innerTabStyle = {
  backgroundColor: riPrimary[10],
  borderRadius: '20px',
  paddingX: '15px',
};

export const ActiveTabMap: { [key in any]: string } = {
  checklist: 'Checklist',
  signature: 'Signatures',
  complete_draft: 'Complete Draft',
};

interface IProps {
  activeTab: string;
}

const ViewDraft: React.FC<IProps> = ({ activeTab }) => {
  const { id, version } = useParams<{ id: string; version: string }>();

  const [addedSignatureFields, setAddedSignatureFields] =
    useState<boolean>(false);
  const [dropPoint, setDropPoint] = useState<any>();
  const [loaded, setLoaded] = useState<boolean>(true);
  const [instance, setInstance] = useState<any>();
  const [canEdit, setCanEdit] = useState<boolean>(true);
  const [saveDraftLoading, setSaveDraftLoading] = useState<boolean>(false);
  const [contractId, setContractId] = useState<string>('');

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: draftData } = useQuery({
    queryKey: ['get_draft_by_id_for_external', id],
    queryFn: async () => {
      const response = await getDraftById(id as string);
      return response;
    },
    enabled: !!id && !!getSessionStorage('external_auth_token'),
  });

  const { data: referenceDocumentResponse } = useQuery({
    queryKey: [
      'view_reference_document_link_external',
      draftData?.link,
      draftData?.template_link,
    ],
    queryFn: () => {
      const payload = {
        file_type: 'view_document',
        link: draftData?.link || draftData?.template_link,
      };
      return fetchPresignedUrlExternal(payload);
    },
    enabled: !!(draftData?.link || draftData?.template_link),
  });

  const fileLink = useMemo(
    () => referenceDocumentResponse?.data?.presigned_url,
    [referenceDocumentResponse?.data?.presigned_url]
  );

  const { mutate: updateDraftData, isPending: updatingDraft } = useMutation({
    mutationFn: editDraftDataExternal,
    onSuccess: (response: any) => {
      queryClient.invalidateQueries({
        queryKey: ['get_draft_by_id_for_external'],
      });
      enqueueSnackbar('Successfully updated draft data!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      instance?.UI.loadDocument(response.data.access_url);
      setLoaded(true);
      if (response.data.status !== draftStatus.CONTRACT_EXECUTED_SUCCESSFULLY) {
        setTimeout(() => {
          removeSessionStorage('external_email');
          removeSessionStorage('external_token');
          removeSessionStorage('external_auth_token');
        }, 5000);
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to update draft data!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: supportDocToUmbrella, isPending: uploadingSupportDoc } =
    useMutation({
      mutationKey: ['support_doc_to_umbrella_ext'],
      mutationFn: contractSupportDocsExt,
      onSuccess: () => {
        enqueueSnackbar('Draft moved successfully to Umbrella!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
      onError: () => {
        enqueueSnackbar('Failed to move Draft to Umbrella!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: sendEmailMutation, isPending: sendingEmail } = useMutation({
    mutationKey: ['send_signature_email'],
    mutationFn: sendEmailExt,
    onSuccess: () => {
      enqueueSnackbar('Email sent successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to send email!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { data: draftStamps } = useQuery({
    queryKey: ['draft-ext-stamps'],
    queryFn: () => fetchExternalStamps(`?draft=${draftData?.id}`),
    enabled: !!draftData?.id,
  });

  const { mutate: updateStampPaperMutation } = useMutation({
    mutationKey: ['update_ext_stamp_paper'],
    mutationFn: updateExtStampPaper,
  });

  const updateExtStamps = async () => {
    const ids = draftStamps?.results?.map((stamp: any) => stamp?.id) || [];
    const payloadStampSubmit = {
      status: 'USED',
      draft: draftData?.id,
    };
    for (const id of ids) {
      const payload = { id, body: payloadStampSubmit };
      await updateStampPaperMutation(payload);
    }
  };

  const { mutate: createDraftMutation, isPending: creatingDraft } = useMutation(
    {
      mutationKey: ['create_draft'],
      mutationFn: async (payload: any) => createDraft(payload),
      onSuccess: (response: any) => {
        queryClient.invalidateQueries({
          queryKey: ['get_draft_by_id_for_external'],
        });
        enqueueSnackbar('Draft edited successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        instance?.UI.loadDocument(response?.data?.access_url);
        setLoaded(true);
        setTimeout(() => {
          removeSessionStorage('external_email');
          removeSessionStorage('external_token');
          removeSessionStorage('external_auth_token');
        }, 5000);
        if (
          response?.data?.status === draftStatus?.CONTRACT_EXECUTED_SUCCESSFULLY
        ) {
          updateExtStamps();
          const payload = {
            draft: response?.data?.id,
            contract: contractId,
          };
          supportDocToUmbrella(payload);

          const emailPayload = {
            draft: id,
            contract_type: 'Contract Executed',
          };
          sendEmailMutation(emailPayload);
        }
      },
      onError: () => {
        enqueueSnackbar('Failed to edit Draft!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    }
  );

  useEffect(() => {
    if (draftData?.id) {
      const user_id = getSessionStorage('external_user_id') || '';

      let ws = new WebSocket(
        process.env.REACT_APP_RIVERUS_CLM_WS_API +
          `ws/signatures/${draftData?.id}/?token=${user_id}`
      );

      ws.onclose = () => {
        ws = new WebSocket(
          process.env.REACT_APP_RIVERUS_CLM_WS_API +
            `ws/signatures/${draftData?.id}/?token=${user_id}`
        );
      };

      ws.onmessage = (event) => {
        const refresh_status: any = JSON.parse(event.data)['refresh'];
        if (refresh_status) {
          setTimeout(() => {
            queryClient.invalidateQueries({
              queryKey: ['get_draft_by_id_for_external', draftData?.id],
            });
          }, 2000);
        }
      };

      // Cleanup the WebSocket connection on component unmount
      return () => {
        ws.close();
      };
    }
  }, [draftData?.id]);

  return (
    <React.Fragment>
      <Box>
        <DraftHeader
          version={parseInt(version as string)}
          name={draftData?.contractName}
          renderAction={() =>
            activeTab === 'complete_draft' ? (
              <Stack direction="row">
                <LoadingButton
                  type="submit"
                  id="save_draft_button"
                  form="complete_draft_form"
                  loading={saveDraftLoading || updatingDraft}
                >
                  Save and Commit
                </LoadingButton>
              </Stack>
            ) : null
          }
          setCanEdit={setCanEdit}
        />
        <Grid container sx={{ mt: 2 }}>
          <Grid item sm={canEdit ? 12 : 6}>
            {loaded && (
              <PdfView
                key={fileLink}
                draftData={draftData}
                fileLink={fileLink}
                instance={instance}
                setInstance={setInstance}
                dropPoint={dropPoint}
                setDropPoint={setDropPoint}
                addedSignatureFields={addedSignatureFields}
                setAddedSignatureFields={setAddedSignatureFields}
                canEdit={canEdit}
                isExternal
              />
            )}
          </Grid>
          <Grid item sm={6} sx={{ paddingLeft: 3 }}>
            <Tabs
              value={activeTab}
              aria-label="Draft tabs"
              variant="scrollable"
              scrollButtons={false}
              sx={innerTabStyle}
            >
              <Tab
                label={ActiveTabMap[activeTab]}
                value={activeTab}
                {...a11yProps(activeTab)}
              />
            </Tabs>
            <Stack
              spacing={1}
              sx={{
                height: '90%',
                overflowY: 'auto',
                backgroundColor: 'riTertiary.50',
                borderRadius: '24px',
                padding: '8px',
                mt: '8px',
              }}
            >
              {activeTab === 'complete_draft' && (
                <CompleteDraft
                  draftData={draftData}
                  instance={instance}
                  updateDraftData={updateDraftData}
                  isExternal
                  setSaveDraftLoading={setSaveDraftLoading}
                />
              )}
              {activeTab === 'checklist' && (
                <ChecklistTab draftData={draftData} isExternal />
              )}
              {activeTab === 'signature' && (
                <SignatureTab
                  createDraft={createDraftMutation}
                  draftData={draftData}
                  instance={instance}
                  dropPoint={dropPoint}
                  addedSignatureFields={addedSignatureFields}
                  setAddedSignatureFields={setAddedSignatureFields}
                  updateDraftData={updateDraftData}
                  isExternal
                  setContractId={setContractId}
                  isContractExecuting={
                    uploadingSupportDoc || sendingEmail || creatingDraft
                  }
                  updatingDraft={updatingDraft}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
        <Footer isExternal />
      </Box>
    </React.Fragment>
  );
};

export default ViewDraft;
