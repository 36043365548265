/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import WebViewer from '@pdftron/webviewer';
import { useMutation, useQueries } from '@tanstack/react-query';

import SettingIcon from '../../RiverusUI/Components/Icons/SettingIcon';
import { getS3ReferencePresignedURL } from '../../Services/Draft';

interface Props {
  setComparisonDrawer: any;
  openComparisonDrawer: boolean;
  draftDetails: any;
  selectedDraftsLink: any;
  onClose: VoidFunction;
  compareFileName: string[];
  comparisonResponse?: any;
}

const ComparisonDialog: React.FC<Props> = ({
  setComparisonDrawer,
  openComparisonDrawer,
  draftDetails,
  selectedDraftsLink,
  onClose,
  compareFileName,
  comparisonResponse,
}) => {
  const [inta, seInsta] = useState<any>('');
  // const [enableKDP] = React.useState(false);
  // const [annotationIds, setannotationIds] = React.useState<any>([]);
  const [isSearch, setSearch] = useState<any>(false);

  const documentContainer = useRef<HTMLDivElement>(null);

  const { data: presignedUrlsData } = useQueries({
    queries:
      comparisonResponse?.compare_versions?.data?.map((item: any) => ({
        queryKey: ['view_reference_document_link', item?.link],
        queryFn: () =>
          getS3ReferencePresignedURL({
            body: {
              file_type: 'view_document',
              link: item?.link,
            },
          }),
        select: (response: any) => response?.response?.data,
        enabled:
          !!openComparisonDrawer &&
          !!comparisonResponse?.compare_versions?.data,
      })) || [],
    combine: (results: any) => {
      const mappedResults =
        results?.map((result: any) => result?.data?.presigned_url) || [];
      return {
        data: mappedResults,
      };
    },
  });

  const convertPDF = async (Core: any, fileURL: any) => {
    // perform the conversion with no optional parameters
    try {
      const buf = await Core.officeToPDFBuffer(fileURL);

      //optionally save the blob to a file or upload to a server
      const blob = new Blob([buf], { type: 'application/pdf' });
      return blob;
    } catch (error) {
      console.error('Error', error);
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      if (
        documentContainer.current != null &&
        presignedUrlsData[0] != undefined &&
        presignedUrlsData[1] != undefined
      ) {
        WebViewer(
          {
            fullAPI: true,
            disableMultiViewerComparison: true,
            path: '/webviewer',
            disabledElements: ['header', 'toolsHeader'],
            loadAsPDF: true,
          },
          documentContainer.current
        ).then((instance: any) => {
          const { UI, Core } = instance;
          const { Annotations } = Core;
          const { Color } = Annotations;

          seInsta(instance);
          instance.UI.openElements(['loadingModal']);

          UI.addEventListener(UI.Events.MULTI_VIEWER_READY, async () => {
            const [documentViewer1, documentViewer2] =
              Core.getDocumentViewers();

            const startCompare = async () => {
              const shouldCompare =
                documentViewer1.getDocument() && documentViewer2.getDocument();

              if (shouldCompare) {
                // Check if both documents loaded before comparing
                const beforeColor = new Color(255, 73, 73, 0.4);
                const afterColor = new Color(21, 205, 131, 0.4);
                const options = {
                  beforeColor,
                  afterColor,
                };

                await documentViewer1.startSemanticDiff(
                  documentViewer2,
                  options
                );
              }
            };

            const options1 = { filename: `${compareFileName[0]}.pdf` };
            const options2 = { filename: `${compareFileName[1]}.pdf` };

            documentViewer1.addEventListener(
              UI.Events.DOCUMENT_LOADED,
              startCompare
            );
            documentViewer2.addEventListener(
              UI.Events.DOCUMENT_LOADED,
              startCompare
            );

            const iframeDoc = instance.UI.iframeWindow.document;

            //access the container element by id of each view
            const leftView = iframeDoc.getElementById('container1');
            const rightView = iframeDoc.getElementById('container2');

            //drop area is the first child of the container when document is not loaded
            leftView.firstChild.style.display = 'none';
            rightView.firstChild.style.display = 'none';

            const leftCross = iframeDoc.getElementById('header1');
            const rightCross = iframeDoc.getElementById('header2');

            leftCross.lastChild.lastChild.style.display = 'none';
            rightCross.lastChild.lastChild.style.display = 'none';

            const blob1 = await convertPDF(Core, presignedUrlsData[0]);
            const blob2 = await convertPDF(Core, presignedUrlsData[1]);

            documentViewer1.loadDocument(blob1, options1);
            documentViewer2.loadDocument(blob2, options2);

            instance.UI.closeElements(['loadingModal']);
          });
          UI.enableFeatures([UI.Feature.MultiViewerMode]);
        });
      }
    }, 200);
  }, [
    documentContainer.current,
    selectedDraftsLink,
    presignedUrlsData[0],
    presignedUrlsData[1],
  ]);

  const [isDark, setTheme] = useState(false);

  const darkTheme = () => {
    const theme = inta.UI.Theme;
    inta.UI.setTheme(theme.DARK);
  };
  const lightTheme = () => {
    const theme = inta.UI.Theme;
    inta.UI.setTheme(theme.LIGHT);
  };

  const search = () => {
    setSearch(true);
    inta.UI.searchText('', {
      caseSensitive: true,
      wholeWord: true,
    });
  };

  return (
    <Dialog
      fullScreen={true}
      fullWidth={true}
      open={openComparisonDrawer}
      className="draft-main"
    >
      <DialogTitle>
        <Box p={0}>
          <Stack
            direction="row"
            gap={1}
            style={{ fontSize: '16px' }}
            alignItems="center"
          >
            <IconButton
              onClick={() => {
                setComparisonDrawer(false);
                onClose();
              }}
            >
              <ArrowBackIcon sx={{ width: '15px' }} />
            </IconButton>
            Compare Draft
          </Stack>
        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ margin: 0, padding: '16px 0' }}>
        <Box height="80vh">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p="0 15px"
          >
            <IconButton
              onClick={() => {
                setTheme(!isDark);
                if (isDark) {
                  lightTheme();
                } else {
                  darkTheme();
                }
              }}
            >
              <SettingIcon color="#88305F" />
            </IconButton>
            <IconButton
              onClick={() => {
                if (isSearch) {
                  inta.UI.closeElements(['searchPanel']);
                  setSearch(false);
                } else {
                  search();
                }
              }}
            >
              <SettingIcon color="#88305F" />
            </IconButton>
          </Stack>
          <Box
            ref={documentContainer}
            id="document-viewer"
            sx={{ height: '100%', padding: 2 }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ComparisonDialog;
