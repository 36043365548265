/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Card, Chip, IconButton, Stack, Typography } from '@mui/material';

import { scrollContainerSx } from '../../Filter/Components/StaticData';

interface IProps {
  searchResultIds: any;
}

const FilterCardView: FC<IProps> = ({ searchResultIds }) => {
  return (
    <Stack
      sx={{
        ...scrollContainerSx,
        maxHeight: '80vh',
        paddingRight: '10px',
      }}
      gap={2}
    >
      {searchResultIds?.map((contractData: any, index: number) => (
        <Card key={index} sx={{ padding: '10px 15px', overflow: 'visible' }}>
          <Stack gap={1}>
            <Stack
              sx={{ color: '#996C84', alignItems: 'center' }}
              direction="row"
            >
              <Typography variant="h6">{contractData.file_name}</Typography>
              <IconButton
                sx={{ color: '#996C84' }}
                onClick={() =>
                  window.open(
                    '/document/documentlibrary/' +
                      btoa(contractData?.id?.toString()),
                    '_blank'
                  )
                }
              >
                <OpenInNewIcon />
              </IconButton>
              {/* {contractData?.terminate_status && (
                      <Chip label="Terminated" />
                    )}
                    {!contractData?.terminate_status && (
                      <>
                        {isExpiredContract(contractData?.end_date) ? (
                          <Chip
                            label="Expired"
                            sx={{ background: '#ff4d4f', color: 'white' }}
                          />
                        ) : (
                          <Chip
                            label="Active"
                            sx={{
                              background: '#87d068',
                              color: 'white',
                            }}
                          />
                        )}
                      </>
                    )} */}
            </Stack>
            {Array.isArray(contractData.contract_type) ? (
              <Typography sx={{ color: '#6D6E71', fontSize: '14px' }}>
                {contractData.contract_type?.[0]}
              </Typography>
            ) : null}
            {contractData.groups.length > 0 && (
              <Stack direction="row" gap={1}>
                {contractData.groups.map((item: string, index: number) => (
                  <Chip
                    key={`contract-group-${index}`}
                    label={item}
                    color="primary"
                    sx={{ fontSize: '13px' }}
                  />
                ))}
                {contractData.projects.map((item: string, index: number) => (
                  <Chip
                    key={`contract-group-${index}`}
                    label={item}
                    color="success"
                    sx={{ fontSize: '13px' }}
                  />
                ))}
              </Stack>
            )}
            {contractData.clauses && (
              <Stack
                gap={1}
                sx={{
                  ...scrollContainerSx,
                  maxHeight: '250px',
                }}
              >
                {contractData.clauses[0].para?.map(
                  (para: any, index: number) => (
                    <Typography
                      key={index}
                      dangerouslySetInnerHTML={{ __html: para.sentence }}
                    />
                  )
                )}
              </Stack>
            )}
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};

export default FilterCardView;
