import axios from 'axios';

import { authApi } from './authApi';
import { getSessionStorage } from '../Authentication/Actions/authentication';

const baseUrl = process.env.REACT_APP_RIVERUS_CLM_API;

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getSessionStorage(`accessToken`)}`,
  },
});

const APIPaths = {
  groupUrl: baseUrl + 'users/groups/',
  reminderUrl: baseUrl + 'reminder/',
};

export const fetchGroups = async () => {
  const res = await fetch(`${APIPaths.groupUrl}`, APIConfig());
  return res.json();
};

export const fetchReminders = async (params: string) => {
  const response = await fetch(
    `${APIPaths.reminderUrl}?${params}`,
    APIConfig()
  );
  return response.json();
};

export const fetchReminderById = async (id: string) => {
  const response = await fetch(`${APIPaths.reminderUrl}${id}/`, APIConfig());
  return response.json();
};

export const fetchReminderByUserId = async (payload: any) => {
  return await authApi.post(
    `${APIPaths.reminderUrl}get-reminder-by-user/`,
    payload
  );
};

export const addContractReminderComments = async (payload: any) => {
  return authApi.post(`reminder/reminder-comments/`, payload);
};

export const createBulkReminder = async (payload: any) => {
  return await authApi.post('reminder/bulk-reminders/', payload);
};

export const editBulkReminder = async (payload: any) => {
  return await authApi.post('reminder/reminder-update/', payload, APIConfig());
};

export const fetchReminderDraftItem = async (id: string): Promise<any> => {
  return await authApi.get(`reminder/?draft_item=${id}`);
};
export const addContractReminderBulkComments = async (payload: any) => {
  return axios.post(
    `${APIPaths.reminderUrl}reminder-comments/comments-bulk-update/`,
    payload,
    APIConfig()
  );
};
