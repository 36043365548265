import React, { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import DrawIcon from '@mui/icons-material/Draw';
import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import { FormContainer } from '../../../RiverusUI/Components/FormContainer';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';

interface Props {
  tempSignatory: any;
  handleDeleteTempSignatory: (id: string) => void;
  method: string;
  addField: any;
  dropPoint: any;
  serialNumber: number;
  pageCount: number;
  getPageCount: VoidFunction;
}

const AddedSignatoriesList: React.FC<Props> = ({
  tempSignatory,
  handleDeleteTempSignatory,
  method,
  addField,
  dropPoint,
  serialNumber,
  pageCount,
  getPageCount,
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [showPageModal, setShowPageModal] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    defaultValues: {
      'signature-page-select': 'current',
      from: null,
      to: null,
    },
  });

  const { control, watch, reset, setValue } = methods;
  const pageType = watch('signature-page-select');
  const from = watch('from') || 0;
  const to = watch('to') || 0;

  useEffect(() => {
    if (from || to) {
      setValue('signature-page-select', '');
    }
  }, [from]);

  useEffect(() => {
    if (pageType) {
      setValue('from', null);
      setValue('to', null);
    }
  }, [pageType]);

  const handleDeleteConfirmation = () => {
    setOpenDeleteModal(false);
    handleDeleteTempSignatory(tempSignatory);
  };

  const dragStart = (e: any) => {
    e.target.style.opacity = 0.5;
    const copy = e.target.cloneNode(true);
    copy.id = 'form-build-drag-image-copy';
    copy.style.width = '250px';
    document.body.appendChild(copy);
    e.dataTransfer.setDragImage(copy, 125, 25);
    e.dataTransfer.setData('text', '');
  };

  const dragEnd = (e: any) => {
    getPageCount();
    setShowPageModal(true);
    e.target.style.opacity = 1;
    document.body.removeChild(
      document.getElementById('form-build-drag-image-copy') as Node
    );
    e.preventDefault();
  };

  const handlePageConfirmation = () => {
    if (to < from) {
      enqueueSnackbar('Page to range is less than page from!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else if (from > pageCount || to > pageCount) {
      enqueueSnackbar('Range is greater than page count!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      setShowPageModal(false);
      addField('SIGNATURE', tempSignatory?.user, dropPoint, pageType, from, to);
      reset();
    }
  };

  return (
    <React.Fragment>
      <List
        style={{
          backgroundColor: '#88305f1f',
          borderRadius: '10px',
          margin: '15px 0',
        }}
      >
        <ListItem
          secondaryAction={
            <Stack direction="row">
              {method !== 'Adobe' && (
                <Tooltip
                  title="  Drag and Drop this Signature Box to fix the signature position on
              the document."
                  arrow
                >
                  <div
                    draggable
                    onDragStart={(e) => dragStart(e)}
                    onDragEnd={(e) => dragEnd(e)}
                  >
                    <IconButton
                      onClick={() => addField('SIGNATURE', tempSignatory?.user)}
                    >
                      <DrawIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}

              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => setOpenDeleteModal(true)}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          }
        >
          <ListItemAvatar>
            <Stack
              sx={{
                height: '30px',
                width: '30px',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#FFFFFF',
                borderRadius: '50%',
                backgroundColor: '#6D264C',
              }}
            >
              <Typography variant="body1">{serialNumber}</Typography>
            </Stack>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Stack>
                <Typography sx={{ textTransform: 'capitalize' }}>
                  {tempSignatory?.user?.first_name ===
                  tempSignatory?.user?.last_name
                    ? tempSignatory?.user?.first_name
                    : `${tempSignatory?.user?.first_name} ${tempSignatory?.user?.last_name}`}{' '}
                  - {tempSignatory?.user?.assignee_type}
                </Typography>
                <Typography>{tempSignatory?.user?.email}</Typography>
              </Stack>
            }
          />
        </ListItem>
      </List>
      {showPageModal && (
        <ReusableConfirmationModal
          open={showPageModal}
          onClose={() => setShowPageModal(false)}
          title="Place signature field(s) on other Pages"
          confirmBtnText="submit"
          cancelBtnText="cancel"
          onConfirm={handlePageConfirmation}
        >
          <FormContainer
            formContext={methods}
            onSuccess={handlePageConfirmation}
          >
            <Stack sx={{ margin: '10px 0 !important' }} spacing={2}>
              <Typography fontSize="18px">Page Range</Typography>
              <RadioButtonGroup
                row
                name="signature-page-select"
                options={[
                  { value: 'current', title: 'Current Page' },
                  { value: 'all', title: 'All Pages' },
                ]}
                valueKey="value"
                control={control}
              />
              {pageCount > 1 && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>From</Typography>
                  <ControlledTextField
                    control={control}
                    name="from"
                    type="number"
                    sx={{ width: '60px' }}
                    size="small"
                  />
                  <Typography>To</Typography>
                  <ControlledTextField
                    control={control}
                    name="to"
                    type="number"
                    sx={{ width: '60px' }}
                    size="small"
                  />
                  <Typography>Out of {pageCount} Pages</Typography>
                </Stack>
              )}
            </Stack>
          </FormContainer>
        </ReusableConfirmationModal>
      )}
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          title="Delete Signatory"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={handleDeleteConfirmation}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected signatory will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </React.Fragment>
  );
};

export default AddedSignatoriesList;
