import { GridRowId } from '@mui/x-data-grid';
import axios from 'axios';

import { authApi } from './authApi';
import { getSessionStorage } from '../Authentication/Actions/authentication';

const APIPaths = {
  getContractType:
    process.env.REACT_APP_RIVERUS_CLM_API + 'admin/contracttype/',
  getGroups: process.env.REACT_APP_RIVERUS_CLM_API + 'users/groups/?type=all',
  approvalBaseUrl: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/approval/',
  getUsers: process.env.REACT_APP_RIVERUS_CLM_API + 'users/org/all/',
  getApprovers:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'users/?role=Approvers&assignee_type=internal',
  getCollaborators:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'users/?role=Collaborators&assignee_type=internal',
  getCheckList: process.env.REACT_APP_RIVERUS_CLM_API + 'admin/checklist/',
  getClauseLibrary: process.env.REACT_APP_RIVERUS_CLM_API + 'clauselibrary/',
  getMergeTag:
    process.env.REACT_APP_RIVERUS_CLM_API + 'data-dictionary/get_field',
  getSectionNames:
    process.env.REACT_APP_RIVERUS_CLM_API + 'data-dictionary/section_names/',
  getContractsTags: process.env.REACT_APP_RIVERUS_CLM_API + `contracts/tags/`,
};

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getSessionStorage(`accessToken`)}`,
  },
});

export const fetchContractType = async () => {
  const res = await fetch(`${APIPaths.getContractType}`, APIConfig());
  return res.json();
};

export const fetchGroups = async () => {
  const res = await fetch(`${APIPaths.getGroups}`, APIConfig());
  return res.json();
};

export const fetchDraftGroups = async (id: string) => {
  const res = await fetch(
    `${APIPaths.approvalBaseUrl}${id}/groups/`,
    APIConfig()
  );
  return res.json();
};

export const fetchRequestApprovals = async (params?: string) => {
  const res = await fetch(
    `${APIPaths.approvalBaseUrl}${params ? params : ''}`,
    APIConfig()
  );
  return res.json();
};

export const fetchMergeTag = async (params: string) => {
  const res = await fetch(
    `${APIPaths?.getMergeTag}?contract_type=${params}`,
    APIConfig()
  );
  return res.json();
};

export const deleteApproval = (fileId: GridRowId[]) => {
  return axios.post(
    APIPaths.approvalBaseUrl + 'bulk-delete/',
    { ids: fileId },
    APIConfig()
  );
};

export const editApprovalData = (id: string, body: any) => {
  const response = axios.patch(
    `${APIPaths.approvalBaseUrl}${id}/`,
    body,
    APIConfig()
  );
  return response;
};

export const getUsers = async () => {
  const URL = process.env.REACT_APP_RIVERUS_CLM_API + 'users/org/all/';
  const res = await fetch(`${URL}`, APIConfig());
  return res.json();
};

export const fetchApprovers = async () => {
  const res = await fetch(`${APIPaths.getApprovers}`, APIConfig());
  return res.json();
};

export const fetchRequisitionApprovers = async () => {
  return await authApi.get(
    'users/?role=Requisition Approvers&assignee_type=internal'
  );
};

export const fetchCollaborators = async () => {
  const res = await fetch(`${APIPaths.getCollaborators}`, APIConfig());
  return res.json();
};

export const fetchCheckList = async (contractId?: string) => {
  const res = await fetch(
    `${APIPaths.getCheckList}?checklist_type=custom&contract_type=${contractId}`,
    APIConfig()
  );
  return res.json();
};

export const deleteChecklist = async (id: string) => {
  return await axios.delete(`${APIPaths.getCheckList}${id}/`, APIConfig());
};

export const addChecklist = async (payload: any) => {
  return await axios
    .post(`${APIPaths.getCheckList}`, payload, APIConfig())
    .then((response) => response.data);
};
export const createApproval = (body: any) => {
  return axios.post(APIPaths.approvalBaseUrl, body, APIConfig());
};

export const updateApproval = (id: string, body: any) => {
  return axios.put(`${APIPaths.approvalBaseUrl}${id}/`, body, APIConfig());
};
export const getClauseLibrary = async () => {
  const res = await fetch(`${APIPaths.getClauseLibrary}`, APIConfig());
  return res.json();
};

export const getClauseLibraryData = async (params: any): Promise<any> => {
  const res = await fetch(`${APIPaths.getClauseLibrary}${params}`, APIConfig());
  return res.json();
};

export const fetchClauseLibraryById = async (id: string) => {
  const response = await fetch(
    `${APIPaths.getClauseLibrary}${id}/`,
    APIConfig()
  );
  return response.json();
};

export const updateClauseLibrary = (payload: any) => {
  const { id, body } = payload;
  return axios.patch(`${APIPaths.getClauseLibrary}${id}/`, body, APIConfig());
};

export const deleteClauseLibrary = async (payload: any) => {
  return await axios.delete(`${APIPaths.getClauseLibrary}delete/`, {
    data: payload,
    ...APIConfig(),
  });
};

export const getSectionNames = async (): Promise<any> => {
  const res = await fetch(`${APIPaths.getSectionNames}`, APIConfig());
  return res.json();
};

export const addClauseLibrary = async (payload: any) => {
  return await axios
    .post(`${APIPaths.getClauseLibrary}`, payload, APIConfig())
    .then((response) => response.data);
};

export const addTags = async (payload: any) => {
  return await axios
    .post(`${APIPaths.getContractsTags}`, payload, APIConfig())
    .then((response) => response.data);
};

export const fetchClauseLibraryData = async () => {
  const res = await fetch(`${APIPaths.getClauseLibrary}`, APIConfig());
  return res.json();
};

export const fetchApprovalData = async (id: string) => {
  const res = await fetch(`${APIPaths.approvalBaseUrl}${id}/`, APIConfig());
  return res.json();
};

export const fetchContractCategory = async (id: string) => {
  return await authApi.get(`admin/contract-category/?contract_types=${id}`);
};

export const fetchAllContractCategory = async () => {
  return await authApi.get(`admin/contract-category/`);
};

export const fetchContractTypesHasPolicy = async (
  params = ''
): Promise<any> => {
  return await authApi.get(`admin/contracttype/${params}`);
};
