import React, { useMemo, useState } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import LoadingAnim from '../../../Assessments/Components/loadingAnim';
import {
  getSessionStorage,
  setSessionStorage,
} from '../../../Authentication/Actions/authentication';
import {
  getIPAddress,
  getProfile,
  trackLogin,
} from '../../../Services/authApi';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UserAuthChecker = (ComposedClass: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const AuthenticationCheck = (props: any) => {
    const [loading, setLoading] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const profile = useMemo(() => getSessionStorage('user_profile'), []);

    const { mutate: trackLoginMutation } = useMutation({
      mutationKey: ['track_login'],
      mutationFn: trackLogin,
    });

    const { mutate: getIPAddressMutation } = useMutation({
      mutationKey: ['getIPAddress'],
      mutationFn: getIPAddress,
      onSuccess: (response: any) => {
        if (response?.data?.ip) {
          trackLoginMutation({ ip_address: response?.data?.ip });
        }
      },
    });

    useQuery({
      queryKey: ['profile'],
      queryFn: async () => {
        setLoading(true);
        const response = await getProfile();

        if (response) {
          const profile = response as any;

          setSessionStorage('user_profile', profile);
          setSessionStorage('user_id', profile.id);
          setSessionStorage(
            'user_name',
            `${profile.first_name} ${profile.last_name}`
          );
          getIPAddressMutation();
          setLoading(false);
        }
      },
      enabled: !profile,
    });

    if (loading) {
      return <LoadingAnim />;
    } else return <ComposedClass {...props} />;
  };

  return AuthenticationCheck;
};

export default UserAuthChecker;
