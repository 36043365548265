import * as React from 'react';

import { Stack, Typography } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

interface DateRangePickerElementProps extends DateRangePickerProps {
  startName?: string;
  endName?: string;
}

const DateRangePicker = (props: DateRangePickerElementProps) => {
  const {
    value,
    onChange,
    disableFuture = false,
    startName = '',
    endName = '',
    ...rest
  } = props;

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <DatePicker
        label={startName}
        format="DD/MM/YYYY"
        value={value?.start || null}
        onChange={(start) => {
          onChange({ ...value, start: start as Date });
        }}
        disableFuture={disableFuture}
        {...rest}
      />

      <Typography> - </Typography>

      <DatePicker
        label={endName}
        format="DD/MM/YYYY"
        value={value?.end || null}
        onChange={(start) => {
          onChange({ ...value, end: start as Date });
        }}
        disableFuture={disableFuture}
        {...rest}
      />
    </Stack>
  );
};

type DateRangePickerValueType = {
  start?: any;
  end?: any;
};

interface DateRangePickerProps extends Omit<DatePickerProps<any>, 'value'> {
  value: DateRangePickerValueType | null;
  onChange: (value: DateRangePickerValueType) => void;
}

export default DateRangePicker;
