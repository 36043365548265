import axios from 'axios';

import AUTH0 from '../../auth_config';
import { getSessionStorage } from '../../Authentication/Actions/authentication';
import {
  AddAnnotation,
  AddComment,
  PresignedData,
  PresignedDataField,
} from '../State/DraftState';

const APIPaths = {
  getTemplatePresignedURL: `${AUTH0.apiBasePath}stylus/templates/aws/presigned-url/`,
  getS3PresignedURL: `${AUTH0.apiBasePath}stylus/aws/presigned-url/`,
  getContractDrafts: `${AUTH0.apiBasePath}stylus/draft/get_latest/`,
  postContractMetaData: `${AUTH0.apiBasePath}stylus/draft/`,
  getUsers: `${AUTH0.apiBasePath}users/`,
  getDraftById: `${AUTH0.apiBasePath}stylus/draft/`,
  getProjects: `${AUTH0.apiBasePath}stylus/project/`,
  getContracts: `${AUTH0.apiBasePath}stylus/draft/`,
  supportDoc: `${AUTH0.apiBasePath}stylus/support_doc/`,
  getsupportdocbyid: `${AUTH0.apiBasePath}stylus/support_doc/`,
  getContractsTags: `${AUTH0.apiBasePath}contracts/tags/`,
  getRequisition: `${AUTH0.apiBasePath}stylus/requisition_form/`,
  getRequisitionbyid: `${AUTH0.apiBasePath}stylus/requisition_form/`,
  getexternalgroup: `${AUTH0.apiBasePath}users/groups/users/?group_type=external`,
  getinternalgroup: `${AUTH0.apiBasePath}users/groups/users/?group_type=internal`,
  getcontractdata: `${AUTH0.apiBasePath}contracts/`,
  sendEmail: `${AUTH0.apiBasePath}sending_email/`,
  compareVersion: `${AUTH0.apiBasePath}stylus/compare_version/`,
  getrequestApprovals: `${AUTH0.apiBasePath}stylus/request_approval/`,
  getTemplates: `${AUTH0.apiBasePath}stylus/templates/`,
  addComment: `${AUTH0.apiBasePath}stylus/draft/draft_comment/`,
  addAnnotation: `${AUTH0.apiBasePath}stylus/draft/draft_annotation/`,
  download: `${AUTH0.apiBasePath}stylus/aws/presigned-url/download/`,
};

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getSessionStorage(`accessToken`)}`,
  },
});

class UploadFileDA {
  get_s3_presigned_url = (fileInfo: any) =>
    axios
      .post(
        fileInfo.file_type === 'template' ||
          fileInfo.file_type === 'support_doc_template'
          ? APIPaths.getTemplatePresignedURL
          : APIPaths.getS3PresignedURL,
        { ...fileInfo },
        APIConfig()
      )
      .then((response) => response.data);

  upload_file_in_s3_bucket = (
    presignedPostData: PresignedData,
    file: any,
    onHandleFileProgress: any
  ) => {
    // create a form obj
    const formData = new FormData();

    // append the fields in presignedPostData in formData
    const presignedDataField: PresignedDataField = presignedPostData.fields;
    Object.keys(presignedDataField).forEach((key) => {
      formData.append(key, presignedDataField[key as keyof PresignedDataField]);
    });

    formData.append('file', file);

    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    };

    return axios
      .post(presignedPostData.url, formData, {
        axiosConfig,
        ...onHandleFileProgress,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.error(error);
      });
  };
}

export class uploadTemplateDA {
  get_s3_presigned_url = (fileInfo: any) =>
    axios
      .post(APIPaths.getS3PresignedURL, { ...fileInfo }, APIConfig())
      .then((response) => response.data);

  upload_file_in_s3_bucket = (
    presignedPostData: PresignedData,
    file: any,
    onHandleFileProgress: any
  ) => {
    // create a form obj
    const formData = new FormData();

    // append the fields in presignedPostData in formData
    const presignedDataField: PresignedDataField = presignedPostData.fields;
    Object.keys(presignedDataField).forEach((key) => {
      formData.append(key, presignedDataField[key as keyof PresignedDataField]);
    });

    formData.append('file', file);

    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
      // crossDomain: true,
    };

    return axios
      .post(presignedPostData.url, formData, {
        axiosConfig,
        ...onHandleFileProgress,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.error(error);
      });
  };
}

export const getContracts = (pageNumber: number) =>
  axios
    .get(
      APIPaths.getContractDrafts +
        `?filter_parameter=Group&&page=${pageNumber}`,
      APIConfig()
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error('Get Draft error', error);
    });
export const getContractsall = () =>
  axios
    .get(APIPaths.getContractDrafts + `?filter_parameter=Group`, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('Get Draft error', error);
    });

export const deleteS3Draft = (filePath: string) =>
  axios
    .delete(filePath)
    .then((response) => response)
    .catch((error) => {
      console.error('deleteS3Draft error', error);
    });

export const deleteDraft = (fileId: string) =>
  axios
    .delete(APIPaths.getContracts + fileId + '/', APIConfig())
    .then((response) => response)
    .catch((error) => {
      console.error('deleteDraft error', error);
    });

export const uploadContractMetaData = (post: any) =>
  axios
    .post(APIPaths.postContractMetaData, post, APIConfig())
    .catch((error) => {
      console.error('uploadContractMetaData', error);
    });

export const updateContractMetaData = (post: any, id: any) => {
  delete post['updateVersionData'];
  delete post['id'];
  return axios
    .patch(`${APIPaths.postContractMetaData}${id}/`, post, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadContractMetaData', error);
    });
};

export const getdraftbyid = (id: any) =>
  axios
    .get(`${APIPaths.getDraftById}${id}/`, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadContractMetaData', error);
    });

export const getreqbyidbyid = (id: any) =>
  axios
    .get(`${APIPaths.getRequisitionbyid}${id}/`, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadContractMetaData', error);
    });

export const getsupportdocbyid = (id: any) =>
  axios
    .get(`${APIPaths.getsupportdocbyid}${id}/`, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadContractMetaData', error);
    });

export const editContractMetaData = (fileId: string, post: any) =>
  axios
    .put(APIPaths.postContractMetaData + fileId + '/', post, APIConfig())
    .then((response) => response)
    .catch((error) => {
      console.error('deleteDraft error', error);
    });

export const getUsers = (role: string) =>
  axios
    .get(APIPaths.getUsers + `?role=${role}`, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('getUsers', error);
    });

export const getUserFromId = (userId: string) =>
  axios
    .get(APIPaths.getUsers + `${userId}/`, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('getUsers', error);
    });

export const getProjects = () =>
  axios
    .get(APIPaths.getProjects, APIConfig())
    .then((response) => response.data)
    .catch((error) => console.error(error));

export const postProjects = (name: any) =>
  axios
    .post(APIPaths.getProjects, name, APIConfig())
    .then((response) => response.data)
    .catch((error) => console.error(error));

export const getVersionHistory = (id: string) =>
  axios
    .get(APIPaths.getContracts + `?search=${id}`, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('getVersionHistory', error);
    });

export const getRequisitionVersionHistory = (id: string) =>
  axios
    .get(APIPaths.getRequisition + `?search=${id}`, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('getRequisitionVersionHistory', error);
    });

export const getGroups = () =>
  axios
    .get(APIPaths.getUsers + 'groups/', APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('getGroups', error);
    });

export const uploadSupportDoc = (payload: any) =>
  axios
    .post(APIPaths.supportDoc, payload, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadSupportDoc', error);
    });

export const uploadsupportdocnew = (payload: any) =>
  axios
    .post(APIPaths.supportDoc, payload, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadSupportDoc', error);
    });
export const deletesupportdocsingle = (id: any) =>
  axios
    .delete(APIPaths.supportDoc + `${id}/`, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('getSupportDocs', error);
    });
export const getSupportDocs = (id: string) =>
  axios
    .get(APIPaths.supportDoc + `?ref_req=${id}`, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('getSupportDocs', error);
    });

export const getContractsTags = () =>
  axios
    .get(APIPaths.getContractsTags, APIConfig())
    .then((response) => response.data)
    .catch((error) => console.error(error));

// export const getexternalgroups=()=>{
//     axios
//     .get(APIPaths.getexternalgroup, APIConfig())
//     .then((response) => {
//         if(response){
//           return response.data
//         }else{
//          return []
//         }
//       })
//       .catch((error) => console.error(error));
//   }
export const getexternalgroups = () =>
  axios
    .get(APIPaths.getexternalgroup, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('getSupportDocs', error);
    });

export const getinternalgroups = () =>
  axios
    .get(APIPaths.getinternalgroup, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('getSupportDocs', error);
    });

export const getcontracts = () =>
  axios
    .get(APIPaths.getcontractdata, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('getcontractdata', error);
    });
export const sendemailtoapproval = (payload: any) =>
  axios
    .post(APIPaths.sendEmail, payload, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadSupportDoc', error);
    });

export const uploadCompareVersion = (payload: any) => {
  return axios
    .post(APIPaths.compareVersion, payload, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadSupportDoc', error);
    });
};

export const getCompareVersion = (id: any) => {
  return axios
    .get(APIPaths.compareVersion + `?search=${id}`, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadSupportDoc', error);
    });
};

export const deleteCompareVersion = (data: any) => {
  axios
    .delete(APIPaths.compareVersion, {
      ...APIConfig(),
      data,
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadSupportDoc', error);
    });
};

export const getRequestApprovals = (id: string) =>
  axios
    .get(APIPaths.getrequestApprovals + `?draft_id=${id}`, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('getRequestApprovals', error);
    });

export const getTemplates = () => {
  return axios
    .get(APIPaths.getTemplates, APIConfig())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Get templates error', error);
    });
};

export const deleteProjects = (id: any) => {
  return axios
    .delete(APIPaths.getProjects + `${id}/`, {
      ...APIConfig(),
    })
    .then(() => true)
    .catch(() => {
      return false;
    });
};

// Annotation and Comments

export const addComment = (payload: AddComment) => {
  const URL = APIPaths.addComment;
  return axios
    .post(URL, payload, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadSupportDoc', error);
    });
};

export const getComment = (draft_id: string) => {
  const URL = `${APIPaths.addComment}?ref_draft=${draft_id}`;
  return axios
    .get(URL, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadSupportDoc', error);
    });
};

export const updateComment = (payload: any, id: string) => {
  const URL = `${APIPaths.addComment}${id}/`;
  return axios
    .patch(URL, payload, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadSupportDoc', error);
    });
};

export const deleteComments = (id: any) => {
  return axios
    .delete(APIPaths.addComment + `${id}/`, {
      ...APIConfig(),
    })
    .then(() => true)
    .catch(() => {
      return false;
    });
};

export const deleteAnnotations = (id: any) => {
  return axios
    .delete(APIPaths.addAnnotation + `${id}/`, {
      ...APIConfig(),
    })
    .then(() => true)
    .catch(() => {
      return false;
    });
};

export const addAnnotationValue = (payload: AddAnnotation) => {
  const URL = APIPaths.addAnnotation;
  return axios
    .post(URL, payload, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadSupportDoc', error);
    });
};

export const getAnnotation = (draft_id: string) => {
  const URL = `${APIPaths.addAnnotation}?ref_draft=${draft_id}`;
  return axios
    .get(URL, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('uploadSupportDoc', error);
    });
};

export const deleteAnnotation = (id: any) => {
  return axios
    .delete(APIPaths.addAnnotation + `${id}/`, {
      ...APIConfig(),
    })
    .then(() => true)
    .catch(() => {
      return false;
    });
};

export const updateAnnotation = (post: any, id: any) => {
  return axios
    .patch(`${APIPaths.addAnnotation}${id}/`, post, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
    });
};
export const getIPAddress = () => {
  return axios.get('https://api.ipify.org/?format=json');
};

export const downloadDocument = (post: any) => {
  return axios
    .post(`${APIPaths.download}`, post, APIConfig())
    .then((response) => response.data)
    .catch((error) => {
      console.error('donwloadDoc', error);
    });
};

export default new UploadFileDA();
