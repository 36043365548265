import React, { useCallback, useMemo, useRef } from 'react';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { formatPayloadDate } from '../../../Draft/Component/Helper';
import DatePickerElement from '../../../RiverusUI/Components/ControlledDatePicker';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import { createReminder } from '../../../Services/DocumentLibrary';

interface ReminderDraftCreatedModalProps {
  open: boolean;
  onClose: VoidFunction;
  approvalData: any;
  draftData: any;
  updateDraftData: any;
  loadingUpdateItem: boolean;
}

const ReminderDraftCreatedModal: React.FC<ReminderDraftCreatedModalProps> = ({
  open,
  onClose,
  approvalData,
  updateDraftData,
  draftData,
  loadingUpdateItem,
}) => {
  const { handleSubmit, control } = useForm();
  const remainingCalls = useRef(0);

  const handleClosePopup = useCallback(() => {
    onClose();
    const payload = {
      id: draftData?.id,
      body: {
        is_seen: true,
      },
    };
    updateDraftData(payload);
  }, [onClose, draftData, updateDraftData]);

  const { mutate: createReminderMutation } = useMutation({
    mutationKey: ['create_reminder'],
    mutationFn: createReminder,
    onSuccess: () => {
      remainingCalls.current -= 1;
      if (remainingCalls.current === 0) {
        handleClosePopup();
      }
      enqueueSnackbar('Reminder created successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to create Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const users = useMemo(() => {
    const approvers: any[] = [];
    approvalData?.map((item: any) => {
      item?.approvers?.map((approversItem: any) => {
        approvers.push(approversItem);
      });
    });
    return approvers;
  }, [approvalData]);

  const onSubmit = useCallback(
    (data: any) => {
      users?.forEach((user: any) => {
        remainingCalls.current = users.length;
        const reminderPayload: any = {
          users: [user?.approvers?.id],
          draft: approvalData[0]?.draft || '',
          reminder_type: 'approval',
          app: 'Stylus',
          name: `${draftData?.contractName} - approval reminder`,
          due_date: data?.due_date
            ? formatPayloadDate(new Date(data?.due_date))
            : '',
        };

        createReminderMutation(reminderPayload);
      });
    },
    [approvalData, createReminderMutation, users, draftData?.contractName]
  );

  return (
    <CustomModal
      open={open}
      handleClose={handleClosePopup}
      title="Contract draft created successfully"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack pt="20px" width="600px" spacing={2}>
          <Typography variant="subtitle2" color="#000">
            This draft requires approval from the following people:
          </Typography>

          <Stack direction="row" spacing={1}>
            {users?.map((data: any, index: number) => (
              <Stack
                direction="row"
                alignItems="center"
                key={index}
                sx={{
                  background: '#FFF7FA',
                  padding: '6px 12px',
                  borderRadius: '8px',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                <PersonOutlineIcon
                  sx={{
                    color: '#88305F',
                    fontSize: '14px',
                    width: '14px',
                    marginRight: '4px',
                  }}
                />
                {data?.approvers?.first_name}
              </Stack>
            ))}
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle2" fontWeight="500" color="#000">
              Set a due date for the approval:
            </Typography>
            <DatePickerElement
              name="due_date"
              label="Due Date"
              control={control}
              required
              disablePast
            />
          </Stack>
          <Stack alignItems="center" direction="row">
            <LoadingButton
              type="submit"
              loading={loadingUpdateItem}
              loadingPosition="start"
              variant="contained"
            >
              Create Reminder and Continue
            </LoadingButton>
            <Button variant="outlined" onClick={handleClosePopup}>
              Close and go back
            </Button>
          </Stack>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default ReminderDraftCreatedModal;
