import React, { useEffect, useMemo, useRef, useState } from 'react';

import WebViewer, {
  Core as PdftronCore,
  WebViewerInstance,
} from '@pdftron/webviewer';

import { getTodayDate, signatureMethod } from './Helper';
import { useUserData } from '../../App/Component/UserDataProvider';
import { draftStatus } from '../State/DraftState';

const DocxViewerComponent = (props: any) => {
  const { user_data, external_email_id, external_signatory_id } = useUserData();

  const documentContainer = useRef<HTMLDivElement>(null);
  const {
    fileLink,
    setInstance,
    dragOver,
    drop,
    draftDetails,
    instance,
    isExternal,
    setDocumentLoading,
    internalSignatoryId,
    signatureFormat,
  } = props;

  const [signatureAnnot, setSignatureAnnot] = useState<any | null>();

  const id = useMemo(
    () =>
      isExternal
        ? external_signatory_id || external_email_id
        : internalSignatoryId || user_data?.email,
    [
      isExternal,
      user_data?.email,
      external_email_id,
      internalSignatoryId,
      external_signatory_id,
    ]
  );

  const userIsSignatory = useMemo(
    () =>
      draftDetails?.signatories?.find(
        (signatory: any) =>
          signatory?.signatory_id === internalSignatoryId ||
          signatory?.signatory_id === external_signatory_id ||
          signatory?.email === user_data?.email
      ),
    [draftDetails, internalSignatoryId, external_signatory_id, user_data?.email]
  );

  const signature_method = useMemo(
    () =>
      signatureMethod.filter((method) => {
        return signatureFormat?.indexOf(method) === -1;
      }),
    [signatureFormat]
  );

  useEffect(() => {
    if (fileLink && documentContainer.current) {
      setDocumentLoading?.(true);

      WebViewer(
        {
          path: '/webviewer',
          fullAPI: true,
          css: '/styles/webviewer.css',
          ui: 'legacy',
          disabledElements: [
            'header',
            'toolsHeader',
            'linkButton',
            'annotationCommentButton',
            'contextMenuPopup',
          ],
          licenseKey:
            'Riverus Technology Solutions Pvt  Ltd :OEM:Riverus::B+:AMS(20250530):7255CE8C0477C80A8360B03AB9C2727160610FEBB9361A37E36C05DA5CF50ED654BA31F5C7',
        },
        documentContainer.current
      ).then((instance) => {
        const { UI, Core } = instance;
        setInstance(instance);
        const { documentViewer, Annotations } = Core;
        documentViewer.loadDocument(fileLink);
        const tool: PdftronCore.Tools.SignatureCreateTool =
          Core.documentViewer.getTool(
            'AnnotationCreateSignature'
          ) as PdftronCore.Tools.SignatureCreateTool;

        tool.setSigningMode(
          instance.Core.Tools.SignatureCreateTool.SigningModes.APPEARANCE
        );

        UI.annotationPopup.update([{ type: 'ac' }]);

        const callback = () => {
          // unsubscribe immediatly after invoking
          documentViewer.removeEventListener('documentLoaded', callback);
        };

        documentViewer.addEventListener('documentLoaded', async () => {
          const FitMode = UI.FitMode;
          UI.setFitMode(FitMode.FitWidth);
          setDocumentLoading?.(false);
          UI.setSelectedTab('signatureModal', 'textSignaturePanelButton');
          callback();
        });

        const doc = documentContainer.current;
        if (doc) {
          doc.addEventListener('dragover', dragOver);
          doc.addEventListener('drop', (e: any) => {
            drop(e, documentViewer);
          });
        }

        Annotations.setCustomCreateSignHereElementHandler(function (
          tool,
          { originalCreateSignHereElement }
        ) {
          const signHereElement = originalCreateSignHereElement(tool);
          signHereElement.innerHTML = 'Click Here To Sign';
          return signHereElement;
        });
      });
    }
  }, [fileLink, documentContainer]);

  useEffect(() => {
    if (!instance) return;
    const { UI } = instance as WebViewerInstance;
    if (signature_method.length) {
      UI.disableElements([...signature_method, 'toolsOverlay']);
    } else {
      UI.disableElements(['toolsOverlay']);
    }
  }, [signature_method, instance]);

  useEffect(() => {
    if (!instance) return;
    const { Core } = instance as WebViewerInstance;
    console.log(userIsSignatory, 'userIsSignatory');
    if (
      userIsSignatory &&
      !userIsSignatory?.signed_date &&
      draftDetails?.signature_method === 'Stylus (Riverus)'
    ) {
      const capitalize = (str: string) =>
        str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

      if (userIsSignatory?.user_type === 'external') {
        const name = capitalize(userIsSignatory?.name);
        Core.annotationManager.setCurrentUser(name);
      } else {
        const firstName = capitalize(
          userIsSignatory?.first_name || userIsSignatory?.name
        );
        const lastName = capitalize(userIsSignatory?.last_name);

        Core.annotationManager.setCurrentUser(`${firstName} ${lastName}`);
      }
    }
  }, [draftDetails, userIsSignatory, instance]);

  useEffect(() => {
    if (!instance) return;
    const { UI, Core } = instance as WebViewerInstance;
    const { Annotations, annotationManager } = Core;
    annotationManager.addEventListener(
      'annotationChanged',
      (annotations, action, { imported }) => {
        annotations.forEach((annot: any) => {
          annot.NoRotate = false;
        });

        if (draftDetails.status === draftStatus.SIGNATURE_PENDING) {
          annotations.forEach((annot: any) => {
            annot.NoMove = true;
            annot.NoResize = true;
            annot.NoRotate = true;
            annot.ReadOnly = true;
          });
        }

        annotations.forEach((annot: any) => {
          if (annot.Subject?.toLowerCase() === 'signature') {
            annot.NoMove = true;
            annot.NoResize = true;
            annot.NoRotate = true;
            annot.setCustomData('user', '');
            if (!annot.getCustomData('signedDate')) {
              annot.setCustomData('signedDate', getTodayDate());
              setSignatureAnnot(annot);
            }
          }
        });

        if (imported && action === 'add') {
          annotations.forEach(function (annot: any) {
            if (annot instanceof Annotations.WidgetAnnotation) {
              if (annot.getCustomData('user') !== id) {
                annot.Hidden = true;
                annot.Listable = false;
              }
            }
          });
        }

        if (draftDetails.status === draftStatus.SIGNATURE_ABORTED) {
          annotations.forEach((annot: any) => {
            annotationManager.deleteAnnotation(annot);
          });
        }
      }
    );

    if (!draftDetails?.open_collab) {
      UI.disableElements(['textPopup']);
    }
  }, [draftDetails, instance]);

  useEffect(() => {
    if (!instance) return;
    if (signatureAnnot) {
      const { Annotations, documentViewer } = instance.Core;
      const annotationManager = documentViewer.getAnnotationManager();
      annotationManager
        .getAnnotationsList()
        .filter(
          (annot: any) =>
            annot instanceof Annotations.SignatureWidgetAnnotation &&
            annot.getCustomData('user') === id
        )
        .forEach((annot: any) => {
          if (annot.isSignedByAppearance()) return;
          const annotCopy = annotationManager.getAnnotationCopy(signatureAnnot);
          annotCopy.PageNumber = annot.getPageNumber();
          annotCopy.X = annot.getX();
          annotCopy.Y = annot.getY();
          annotationManager.addAnnotation(annotCopy);
          annot.sign(annotCopy);
        });
      annotationManager
        .getAnnotationsList()
        .filter(
          (annot: any) =>
            annot instanceof Annotations.WidgetAnnotation &&
            annot.getCustomData('user') === id
        )
        .forEach((annot: any) => {
          annot.Hidden = true;
          annot.Listable = false;
        });
      setSignatureAnnot(null);
    }
  }, [instance, signatureAnnot, id]);

  return (
    <div
      ref={documentContainer}
      id="document-viewer"
      style={{ height: '80vh' }}
    />
  );
};

export default DocxViewerComponent;
